import Strings from './StatusHelper.strings';
export default class StatusHelper {

    static formatStatus(text, localized) {
        if (text) {
            if (text === "InProgress") {
                return localized.BeingProcessed;
            }
            return localized[text];
        }
    }

    static formatOrderStatus(orderStatus, localized) {
        let status;
        const local = Strings.localize();
        if (localized) {
            if (orderStatus) {
                if (orderStatus === "InProgress" || orderStatus == 0) {
                    status = localized.BeingProcessed;
                }
                else {
                    status = localized.Shipped;
                }
            }
        } else {
            if (orderStatus === "InProgress" || orderStatus == 0) {
                status = local.BeingProcessed;
            }
            else {
                status = local.Shipped;
            }
        }

        return status;
    }

    static formatQuoteStatus(quoteStatus, localized) {
        let status;
        status = localized[quoteStatus];
        return status;
    }

    static formatDashBoardOrderStatus(orderStatus, localized) {
        if (orderStatus) {
            let status;
            if (orderStatus === "InProgress") {
                status = localized.Open;
            }
            else {
                status = localized.Complete;
            }
            return status;
        }
    }
}

